// i18next-extract-mark-ns-start products-family-mechanics

import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Banner from '@components/Banner';
import ContentGrid from '@components/ContentGrid';
import Headline from '@components/Headline';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Breadcrumb } from '@components/Breadcrumbs';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductFamilyStructuralPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const iconEngdyn = getImage(
		data.iconEngdyn.childImageSharp.gatsbyImageData
	);
	const iconValdyn = getImage(
		data.iconValdyn.childImageSharp.gatsbyImageData
	);
	const iconPisdyn = getImage(
		data.iconPisdyn.childImageSharp.gatsbyImageData
	);
	const iconRingpak = getImage(
		data.iconRingpak.childImageSharp.gatsbyImageData
	);
	const iconSabr = getImage(data.iconSabr.childImageSharp.gatsbyImageData);
	const iconFearce = getImage(
		data.iconFearce.childImageSharp.gatsbyImageData
	);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Headline
				image={{
					src: data.icon.childImageSharp.gatsbyImageData,
					alt: '',
				}}>
				<Trans i18nKey="headline">
					<p>
						The essential suite to design, analyse and optimise
						mechanical systems in terms of friction, durability and NVH.
						Our range of specialised tools provides detailed analyses of
						dynamic behaviour of engine and transmission components.
					</p>
				</Trans>
			</Headline>

			<ContentGrid>
				<ContentGrid.Item
					image={{
						src: data.gridA.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconEngdyn && (
						<GatsbyImage
							image={iconEngdyn}
							alt="ENGDYN"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_engdyn">
							<h3>
								ENGDYN is a dedicated 3D engine analysis tool
								essential to the design and development of the crank
								train, engine structure and associated components
								including bearings, connecting rods and engine
								mounts.
							</h3>
							<Link
								to={LINKS.PRODUCTS_ENGDYN}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>

				<ContentGrid.Item
					image={{
						src: data.gridB.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconValdyn && (
						<GatsbyImage
							image={iconValdyn}
							alt="VALDYN"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_valdyn">
							<h3>
								VALDYN is a multi-body dynamic and kinematic
								simulation package specifically developed for
								valvetrain and drive-system analysis and cam and
								spring pack design.
							</h3>
							<Link
								to={LINKS.PRODUCTS_VALDYN}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>

				<ContentGrid.Item
					image={{
						src: data.gridC.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconPisdyn && (
						<GatsbyImage
							image={iconPisdyn}
							alt="PISDYN"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_pisdyn">
							<h3>
								PISDYN is an advanced 3D simulation package used to
								predict piston and connecting rod assembly dynamics.
							</h3>
							<Link
								to={LINKS.PRODUCTS_PISDYN}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>

				<ContentGrid.Item
					image={{
						src: data.gridD.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconRingpak && (
						<GatsbyImage
							image={iconRingpak}
							alt="RINGPAK"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_ringpak">
							<h3>
								RINGPAK is an advanced 2D simulation package which
								enables users to predict and optimise ring-pack
								dynamics, lubrication and gas flow.
							</h3>
							<Link
								to={LINKS.PRODUCTS_RINGPAK}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>

				<ContentGrid.Item
					image={{
						src: data.gridE.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconSabr && (
						<GatsbyImage
							image={iconSabr}
							alt="SABR"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_sabr">
							<h3>
								SABR is a proven shaft, gear and bearing conception
								and design package. Find out more
							</h3>
							<Link to={LINKS.PRODUCTS_SABR} className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>

				<ContentGrid.Item
					image={{
						src: data.gridF.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconFearce && (
						<GatsbyImage
							image={iconFearce}
							alt="FEARCE"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_fearce">
							<h3>
								FEARCE is a finite element (FE) pre- and
								post-processing tool written specifically to support
								engine and vehicle analysis.
							</h3>
							<Link
								to={LINKS.PRODUCTS_FEARCE}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>
			</ContentGrid>
		</Page>
	);
};

export default ProductFamilyStructuralPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-family-mechanics", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/banner-data.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/StructuralMechanics_htext_400x200.png" }) {
		...imageBreaker
	}
	gridA: file(relativePath: { eq: "content/engdyn-grid.jpg" }) {
		...imageBreaker
	}
	gridB: file(relativePath: { eq: "content/valdyn-grid.jpg" }) {
		...imageBreaker
	}
	gridC: file(relativePath: { eq: "content/pisdyn-grid.jpg" }) {
		...imageBreaker
	}
	gridD: file(relativePath: { eq: "content/ringpak-grid.jpg" }) {
		...imageBreaker
	}
	gridE: file(relativePath: { eq: "content/sabr-grid.jpg" }) {
		...imageBreaker
	}
	gridF: file(relativePath: { eq: "content/fearce-grid.jpg" }) {
		...imageBreaker
	}
	iconEngdyn: file(relativePath: { eq: "content/rs_engdyn.png" }) {
		...imageBreaker
	}
	iconValdyn: file(relativePath: { eq: "content/rs_valdyn.png" }) {
		...imageBreaker
	}
	iconPisdyn: file(relativePath: { eq: "content/rs_pisdyn.png" }) {
		...imageBreaker
	}
	iconRingpak: file(relativePath: { eq: "content/products/ringpak/rs_ringpak.png" }) {
		...imageBreaker
	}
	iconSabr: file(relativePath: { eq: "content/rs_sabr.png" }) {
		...imageBreaker
	}
	iconFearce: file(relativePath: { eq: "content/rs_fearce.png" }) {
		...imageBreaker
	}
}
`;
